import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

interface FlatpickrInputElement extends HTMLInputElement {
  _flatpickrInstance?: flatpickr.Instance
}

export default class extends Controller {
  static targets = ['input']

  declare readonly inputTarget: FlatpickrInputElement

  connect (): void {
    this.update()
  }

  disconnect (): void {
    this.destroyDatepicker(this.inputTarget)
  }

  update (): void {
    setTimeout(() => {
      const inputType = ['date', 'hidden', 'datetime-local', 'datetime'].includes(this.inputTarget.type)

      if (inputType) {
        this.initializeDatepicker(this.inputTarget)
      } else if (this.inputTarget._flatpickrInstance != null) {
        this.destroyDatepicker(this.inputTarget)
      }
    }, 10)
  }

  initializeDatepicker (inputElement: FlatpickrInputElement): void {
    const enableTime = inputElement.dataset.datepickerEnableTime === 'true'
    inputElement.dataset.originalType = inputElement.type
    let dateFormat = 'Y-m-d'
    let altFormat = this.determineDateFormat()

    if (enableTime) {
      dateFormat += ' H:i'
      altFormat += ' H:i'
    }

    inputElement._flatpickrInstance = flatpickr(inputElement, {
      enableTime,
      dateFormat,
      altFormat,
      altInput: true,
      allowInput: true,
      monthSelectorType: 'static',
      altInputClass: `${inputElement.className} flatpickr-input`,
      minDate: inputElement.min,
      static: true
    })
  }

  destroyDatepicker (inputElement: FlatpickrInputElement): void {
    const datepicker = inputElement._flatpickrInstance

    if (datepicker != null) {
      datepicker.destroy()
      delete inputElement._flatpickrInstance

      inputElement.value = ''
      inputElement.type = inputElement.dataset.originalType !== undefined ? inputElement.dataset.originalType : 'text'
      inputElement.nextElementSibling?.remove()
    }
  }

  // TODO: Fix this function when we will allow changing locale
  determineDateFormat (): string {
    const userLocale = navigator.language
    switch (userLocale) {
      case 'en-US': return 'm/d/Y'
      case 'en-GB': return 'd/m/Y'
      case 'ar-AE': return 'd/m/Y'
      case 'ru-RU': return 'd/m/Y'
      default: return 'Y-m-d'
    }
  }
}
