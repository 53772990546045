import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['existingOrganizationInput', 'organizationBlock', 'userBlock']

  declare existingOrganizationInputTarget: HTMLInputElement
  declare readonly organizationBlockTargets: HTMLDivElement[]
  declare readonly userBlockTargets: HTMLDivElement[]

  connect (): void {
    this.existingOrganizationOnChange()
  }

  existingOrganizationOnChange (): void {
    this.userBlockTargets.forEach((input) => {
      this.toggleVisibility(input, !this.existingOrganization())
    })

    this.organizationBlockTargets.forEach((input) => {
      this.toggleVisibility(input, this.existingOrganization())
    })
  }

  private existingOrganization (): boolean {
    switch (this.existingOrganizationInputTarget.value) {
      case 'true':
        return true
      default:
        return false
    }
  }

  private toggleVisibility (element: HTMLElement, value: boolean): void {
    if (value) {
      element.classList.remove('hidden')
    } else {
      element.classList.add('hidden')
    }
  }
}
