import { Controller } from '@hotwired/stimulus'
import DynamicSelect from '../../modules/dynamic_select'

export default class extends Controller {
  static targets = ['attribute', 'predicate', 'valueInput', 'valueSelect', 'companyValueSelect']

  static values = {
    predicates: Array,
    values: Array,
    valueType: String
  }

  declare readonly attributeTarget: HTMLSelectElement
  declare readonly predicateTarget: HTMLSelectElement
  declare readonly valueInputTarget: HTMLInputElement
  declare readonly valueSelectTarget: HTMLSelectElement
  declare readonly companyValueSelectTarget: HTMLSelectElement

  declare predicatesValue: any[]
  declare valuesValue: any[]
  declare valueTypeValue: string

  declare readonly hasFieldValueTypeTarget: boolean

  predicatesSelect: DynamicSelect
  valuesSelect: DynamicSelect

  initialize (): void {
    this.predicatesSelect = new DynamicSelect(this.predicateTarget)
    this.valuesSelect = new DynamicSelect(this.valueSelectTarget)

    setTimeout(() => {
      this.attributeTarget.dispatchEvent(new Event('change'))
    })
  }

  update ({ detail: { predicates, values, valueType } }: { detail: { predicates: any[], values: any[], valueType: string } }): void {
    this.predicatesValue = predicates
    this.valuesValue = values
    this.valueTypeValue = valueType
  }

  predicatesValueChanged (): void {
    this.predicatesSelect.reset(this.predicatesValue)
    this.toggleValueTarget()
  }

  valuesValueChanged (): void {
    this.valuesSelect.reset(this.valuesValue)
  }

  valueTypeValueChanged (): void {
    switch (this.valueTypeValue) {
      case 'float':
        this.valueInputTarget.type = 'number'
        break
      case 'datetime':
      case 'date':
        if (this.valueInputTarget.value.length <= 1) {
          this.valueInputTarget.value = new Date().toISOString().slice(0, 10)
        }

        this.valueInputTarget.type = 'date'
        break
      default:
        this.valueInputTarget.type = 'text'
    }
  }

  toggleValueTarget (): void {
    setTimeout(() => {
      const noValuePredicate = [
        'true', 'false', 'not_true', 'present', 'blank', 'null', 'not_null',
        'has_single_facility', 'has_multiple_facilities', 'has', 'does_not_have'
      ].includes(this.predicateTarget.value)
      const showSelect = this.valueTypeValue.includes('option') || this.valuesValue.length > 0
      const showInput = !showSelect || this.valueSelectTarget.value === 'custom_date'

      this.toggle(this.valueInputTarget, !noValuePredicate && showInput)
      this.toggleValueSelect(noValuePredicate, showSelect)
    })
  }

  private toggleValueSelect (noValuePredicate: boolean, showSelect: boolean): void {
    const isOrganizationId = this.attributeTarget.value === 'organization_id'

    this.toggle(this.valueSelectTarget, !isOrganizationId && !noValuePredicate && showSelect)

    isOrganizationId ? this.toggleCompaniesSelectElements(showSelect) : this.toggleCompaniesSelectElements(false)
  }

  private toggleCompaniesSelectElements (show: boolean): void {
    this.toggle(this.companyValueSelectTarget, show)
    this.element.querySelectorAll('.ts-wrapper.companies-select').forEach((element) => {
      this.toggle(element as HTMLSelectElement | HTMLInputElement, show)
    })
  }

  toggle (target: HTMLInputElement | HTMLSelectElement, show: boolean): void {
    target.disabled = !show
    target.classList.toggle('hidden', !show)
  }
}
