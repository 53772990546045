import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import { TomInput } from 'tom-select/src/types'
import removeButton from 'tom-select/src/plugins/remove_button/plugin'
import virtualScroll from 'tom-select/src/plugins/virtual_scroll/plugin'
import checkboxOptions from 'tom-select/src/plugins/checkbox_options/plugin'

TomSelect.define('checkbox_options', checkboxOptions)
TomSelect.define('remove_button', removeButton)
TomSelect.define('virtual_scroll', virtualScroll)

export default class extends Controller<HTMLSelectElement> {
  static values = {
    url: { type: String, default: '/companies' }
  }

  declare urlValue: string

  currentPage = 1
  nothingMoreToLoad = false

  plugin: TomSelect

  connect (): void {
    if (this.element.disabled) {
      return
    }

    this.initTomSelect()
    this.setupEventListeners()
  }

  private initTomSelect (): void {
    this.plugin = new TomSelect(this.element as TomInput, {
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      sortField: 'name',
      plugins: ['virtual_scroll'],
      hidePlaceholder: true,
      maxOptions: 99999,
      maxItems: 1,
      preload: true,
      render: {
        option: (data: { name: string }) => `<div><p>${data.name}</p></div>`,
        item: (data: { name: string }) => `<div><p>${data.name}</p></div>`
      },
      firstUrl: () => {},
      shouldLoadMore: () => !this.nothingMoreToLoad,
      onDropdownOpen: this.toggleContainerOverflow,
      onBlur: this.toggleContainerOverflow,
      load: this.loadData.bind(this),
      onType: this.clearOptionsOnType
    })
  }

  private setupEventListeners (): void {
    this.plugin.on('focus', () =>
      setTimeout(() => this.selectDropdownPosition(), 0)
    )
    this.plugin.on('type', () => this.selectDropdownPosition())
    this.plugin.on('load', () => this.selectDropdownPosition())

    this.plugin.dropdown_content.addEventListener(
      'scroll',
      this.loadDataOnScroll
    )
  }

  private readonly loadDataOnScroll = (): void => {
    const dropdown = this.plugin.dropdown_content

    if (
      dropdown.scrollTop + dropdown.clientHeight < dropdown.scrollHeight ||
      this.nothingMoreToLoad
    ) {
      return
    }

    this.loadData(this.plugin.lastQuery, (data: any[]) => {
      this.plugin.addOptions(data)
      this.plugin.refreshOptions(false)
    })
  }

  private readonly clearOptionsOnType = (value: string): void => {
    this.plugin.clearOptions()
    this.currentPage = 1
    this.nothingMoreToLoad = false

    if (value === '') this.plugin.load('')
  }

  loadData (query: string, callback: (data: any[]) => void): void {
    if (query === null) {
      return
    }

    const oldScrollTop = this.plugin.dropdown_content.scrollTop

    fetch(this.buildUrl(query), {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error('Access denied')
        }
        return await response.json()
      })
      .then(data => {
        this.handleDataResponse(callback, oldScrollTop)(data)
      })
      .catch((_) => {
        this.plugin.trigger('no_results')
      })
      .finally(() => this.plugin.trigger('no_results'))
  }

  buildUrl (query: string): string {
    const joinOperator = this.urlValue.includes('?') ? '&' : '?'

    return `${this.urlValue}${joinOperator}page=${this.currentPage}` +
      (query === '' ? '' : `&q[name_cont]=${encodeURIComponent(query)}`)
  }

  private readonly handleDataResponse =
    (callback: (data: any[]) => void, oldScrollTop: number) =>
      (data: any[]): void => {
        callback(data)

        if (data.length > 0) this.currentPage++
        this.nothingMoreToLoad = data.length === 0

        requestAnimationFrame(() => {
          this.plugin.dropdown_content.scrollTop = oldScrollTop
        })
      }

  toggleContainerOverflow (): void {
    document
      .querySelectorAll('.overflow-y-auto, .overflow-y-hidden')
      .forEach((element) =>
        element.dispatchEvent(new Event('overflow-toggle'))
      )
  }

  selectDropdownPosition (): void {
    const dropdown = this.plugin.dropdown
    const inputRect = this.plugin.control_input.getBoundingClientRect()
    const spaceBelow = window.innerHeight - inputRect.bottom

    dropdown.style.top =
      spaceBelow > 260 ? '' : `-${dropdown.clientHeight + 10}px`
  }
}
