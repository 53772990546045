import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleable']
  static values = {
    open: { type: Boolean, default: false }
  }

  declare readonly toggleableTargets: HTMLInputElement[]
  declare openValue: boolean

  toggle (): void {
    this.openValue = !this.openValue
    this.toggleableTargets.forEach(toggleable => (toggleable.disabled = !this.openValue))
  }
}
