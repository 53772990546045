import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect (): void {
    setTimeout(() => {
      const alert = this.element.cloneNode(true) as HTMLElement
      alert.setAttribute('data-controller', 'alert dialog-alert')
      alert.setAttribute('data-dialog-alert-alert-outlet', '.flash')

      document.querySelectorAll('dialog[open]').forEach((dialog) => {
        dialog.prepend(alert)
      })
    }, 100)
  }
}
