import { Controller } from '@hotwired/stimulus'
import { Alert } from 'tailwindcss-stimulus-components'

// HACK: this is a workaround to show the alert in open dialog after request
export default class extends Controller {
  static outlets = ['alert']

  declare readonly alertOutlets: Alert[]

  close (): void {
    this.alertOutlets.forEach((a) => a.close())
  }
}
