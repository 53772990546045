import { Controller } from '@hotwired/stimulus'
import DynamicSelect from '../../modules/dynamic_select'

export default class extends Controller {
  static targets = [
    'type',
    'email',
    'mobile',
    'userId',
    'companyRoleId',
    'authorityRoleId',
    'projectFieldId',
    'facilityFieldId'
  ]

  static values = {
    nameOption: String,
    valueOption: String
  }

  declare readonly typeTarget: HTMLSelectElement
  declare readonly userIdTarget: HTMLSelectElement
  declare readonly companyRoleIdTarget: HTMLSelectElement
  declare readonly authorityRoleIdTarget: HTMLSelectElement
  declare readonly projectFieldIdTarget: HTMLSelectElement
  declare readonly facilityFieldIdTarget: HTMLSelectElement
  declare readonly emailTarget: HTMLInputElement
  declare readonly mobileTarget: HTMLInputElement

  recipientTypesSelect: DynamicSelect

  connect (): void {
    this.recipientTypesSelect = new DynamicSelect(this.typeTarget)
    setTimeout(() => {
      this.dispatch('connected')
      this.changeType()
    })
  }

  update ({ detail: { enable, types } }: CustomEvent): void {
    this.recipientTypesSelect.reset(types)
    this.toggle(this.typeTarget, enable)
    this.changeType()
  }

  changeType (): void {
    [
      this.emailTarget,
      this.mobileTarget,
      this.userIdTarget,
      this.companyRoleIdTarget,
      this.authorityRoleIdTarget,
      this.projectFieldIdTarget,
      this.facilityFieldIdTarget
    ].forEach((input) => {
      this.toggle(input, false)
    })

    const input = this.recipientInput()

    if (input != null) {
      this.toggle(input, true)
    }
  }

  private toggle (input: HTMLInputElement | HTMLSelectElement, show: boolean): void {
    input.disabled = !show

    this.toggleHiddenClass(input, show)
  }

  private toggleHiddenClass (el: Element, show: boolean): void {
    if (show) {
      el.classList.remove('hidden')
    } else {
      el.classList.add('hidden')
    }
  }

  private recipientInput (): HTMLInputElement | HTMLSelectElement | null {
    if (this.typeTarget.disabled) {
      return null
    }

    switch (this.typeTarget.value) {
      case 'email':
        return this.emailTarget
      case 'sms':
        return this.mobileTarget
      case 'company_role':
        return this.companyRoleIdTarget
      case 'authority_role':
        return this.authorityRoleIdTarget
      case 'project_email_field':
        return this.projectFieldIdTarget
      case 'facility_email_field':
        return this.facilityFieldIdTarget
      case 'user':
        return this.userIdTarget
    }

    return null
  }
}
